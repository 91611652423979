// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Fields
import { Checkbox, ResponsiveModal } from "fields";

class ConfirmCloseOrderModal extends React.Component {
  state = { sendOrderReadyText: true };

  onConfirmClosePickupOrder = () => {
    this.props.onClosePickupOrder({
      sendOrderReadyText: this.state.sendOrderReadyText,
    });
    this.props.onCloseModal();
  };

  render() {
    const { sendOrderReadyText } = this.state;
    return (
      <ResponsiveModal
        contentLabel="confirm close order modal"
        contentDescriptionProps={{
          contentDescription: (
            <div>
              Order will move to <strong>Past Orders</strong> after you click
              Submit
            </div>
          ),
          showContentDescription: true,
        }}
        footerProps={{
          showFooter: true,
          submitButtonProps: {
            activeText: "Submit",
            loadingText: "Submitting",
          },
        }}
        headerProps={{ header: "Close Order" }}
        onCloseModal={this.props.onCloseModal}
        onSubmit={this.onConfirmClosePickupOrder}
      >
        <Checkbox
          id="notify guest"
          checked={sendOrderReadyText}
          label="Notify customer the order is ready for pick up/delivery"
          onClick={() =>
            this.setState({ sendOrderReadyText: !sendOrderReadyText })
          }
        />
      </ResponsiveModal>
    );
  }
}

ConfirmCloseOrderModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onClosePickupOrder: PropTypes.func.isRequired,
};

export default ConfirmCloseOrderModal;
