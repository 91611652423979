// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { del, set } from "object-path-immutable";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import dayjs from "dayjs";

import { _checkIfDateIsDuplicate, _sanitizeSpecialHours } from "./functions";
import { _sortSpecialHours } from "../functions";
import AddNewDateModal from "../AddNewDateModal";

// Style
import ModalStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { Checkbox, ResponsiveModal } from "fields";

// Icons
import { CalendarIcon, PencilIcon, PlusIcon, TrashCanIcon } from "assets/Icons";

import { Functions } from "lib";

class SpecialHoursEditModal extends React.Component {
  state = { close_modal_after_submit: true, special_hours: {} };

  componentDidMount = () =>
    this.setState({ special_hours: this.props.special_hours });

  onSaveNewSpecialHours = () => {
    const sanitized_special_hours = _sanitizeSpecialHours({
      special_hours: this.state.special_hours,
    });
    const has_duplicate_dates = Object.keys(sanitized_special_hours).reduce(
      (result, id) => {
        const { date } = sanitized_special_hours[id];
        const is_duplicate = _checkIfDateIsDuplicate({
          date,
          id,
          special_hours: sanitized_special_hours,
        });
        if (is_duplicate) return true;
        return result;
      },
      false
    );
    if (has_duplicate_dates) {
      this.setState({ close_modal_after_submit: false }, () => {
        Functions.ShowConfirmNotif({
          message: "Please remove duplicate dates",
          options: { autoClose: 4000 },
          type: "warning",
        });
      });
    } else {
      this.setState({ close_modal_after_submit: true }, () => {
        this.props.onSaveNewSpecialHours({
          new_special_hours: sanitized_special_hours,
        });
      });
    }
  };

  renderAddNewDateButton = () => (
    <button
      className={Style.add_new_date}
      onClick={() => this.setState({ show_add_new_date_modal: true })}
    >
      <PlusIcon />
      Add new date
    </button>
  );

  renderContent = () => {
    const { special_hours = {} } = this.state;
    if (Object.keys(special_hours).length === 0) return;
    return _sortSpecialHours({ special_hours }).map((id) => {
      const { date, end_at, is_closed, start_at } = special_hours[id];
      return (
        <div className={Style.special_hour} key={id}>
          <div
            className={Style.special_hour_info_container}
            onClick={() =>
              this.setState({
                selected_date_id_to_edit: id,
                show_add_new_date_modal: true,
              })
            }
          >
            <div className={Style.calendar_date}>
              <div className={Style.calendar_icon}>
                <CalendarIcon />
              </div>
              <div>{date}</div>
            </div>
            <div>
              {is_closed ? (
                <Checkbox checked={is_closed} id={id} label="Closed" readOnly />
              ) : (
                `${dayjs("1/1/1 " + start_at).format("h:mm A")} - ${dayjs(
                  "1/1/1 " + end_at
                ).format("h:mm A")}`
              )}
            </div>
          </div>
          <div className={Style.special_hours_buttons}>
            <div
              className={Style.edit_date}
              onClick={() =>
                this.setState({
                  selected_date_id_to_edit: id,
                  show_add_new_date_modal: true,
                })
              }
            >
              <PencilIcon />
            </div>
            <div
              className={Style.delete_date}
              onClick={() =>
                this.setState({
                  selected_date_id_to_delete: id,
                  show_confirm_date_removal: true,
                })
              }
            >
              <TrashCanIcon />
            </div>
          </div>
        </div>
      );
    });
  };

  renderConfirmDateRemovalModal = () => {
    const { selected_date_id_to_delete = "", special_hours = {} } = this.state;
    const { date = "" } = special_hours[selected_date_id_to_delete] || {};
    return (
      <ResponsiveModal
        classNames={{
          footer_submit_button: Style.confirm_delete_button,
          modal: Style.confirm_date_removal_modal,
        }}
        contentDescriptionProps={{
          contentDescription:
            "This action cannot be undone. Are you sure you want to delete this date?",
          showContentDescription: true,
        }}
        contentLabel="Confirm date removal modal"
        footerProps={{
          showFooter: true,
          submitButtonProps: { activeText: "Delete" },
        }}
        headerProps={{ header: `Delete ${date}` }}
        onCloseModal={() => this.setState({ show_confirm_date_removal: false })}
        onSubmit={() =>
          this.setState(
            {
              selected_date_id_to_delete: "",
              special_hours: del(special_hours, selected_date_id_to_delete),
            },
            this.onSaveNewSpecialHours
          )
        }
      ></ResponsiveModal>
    );
  };

  render() {
    return (
      <>
        {this.state.show_add_new_date_modal && (
          <AddNewDateModal
            onCloseModal={() =>
              this.setState({
                selected_date_id_to_edit: "",
                show_add_new_date_modal: false,
              })
            }
            onSave={({ key, value }) =>
              this.setState(
                { special_hours: set(this.state.special_hours, key, value) },
                this.onSaveNewSpecialHours
              )
            }
            selected_date_id_to_edit={this.state.selected_date_id_to_edit}
            special_hours={this.props.special_hours}
          />
        )}
        {this.state.show_confirm_date_removal &&
          this.renderConfirmDateRemovalModal()}
        <ResponsiveModal
          classNames={{ modal: ModalStyle.modal }}
          closeModalAfterSuccessSubmit={this.state.close_modal_after_submit}
          contentDescriptionProps={{
            contentDescription: this.renderAddNewDateButton(),
            showContentDescription: true,
          }}
          contentLabel="Edit special hours modal"
          headerProps={{ header: "Special Hours" }}
          onCloseModal={this.props.onCloseModal}
          onSubmit={this.onSaveNewSpecialHours}
        >
          <div>{this.renderContent()}</div>
        </ResponsiveModal>
      </>
    );
  }
}

SpecialHoursEditModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onSaveNewSpecialHours: PropTypes.func.isRequired,
  special_hours: PropTypes.object,
};

SpecialHoursEditModal.defaultProps = {
  special_hours: {},
};

export default SpecialHoursEditModal;
