// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { del, set } from "object-path-immutable";
import _get from "lodash.get";

import Buttons from "./Buttons";
import PreviewInfo from "./PreviewInfo";
import OrderDetails from "./OrderDetails";

import {
  _createConfirmMsgForCustomer,
  _createOrderReadyMsgForCustomer,
} from "./helperFunctions";

// Utils
import { _isTrue } from "utils";

// Style
import Style from "./style.module.scss";

// Components
import { Modals } from "components";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Lib
import { Functions, Services } from "lib";

class ShopOrderPill extends React.Component {
  confirmNotif = null;
  state = { showExpandedInfo: false };
  _isMounted = false;

  componentDidMount = () => (this._isMounted = true);

  componentWillUnmount() {
    this.setState({ isLoading: false, showExpandedInfo: false });
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.showExpandedInfo !== prevProps.showExpandedInfo)
      this.setState({ showExpandedInfo: this.props.showExpandedInfo });
  }

  onConfirmActiveOrder = async ({ nextStatus, preparationTime }) => {
    const { ChangeStatusOfActiveOrder } = Services.Merchants.PostRequests;
    this.setState({ isLoading: true });
    const { context, orderID, orderInfo } = this.props;
    const { shopBasicInfo, shopID } = context;
    const params = { nextStatus, orderID, shopID };
    const { success } = await ChangeStatusOfActiveOrder(params);
    const { allowPrintingOrder = "false" } = shopBasicInfo;

    if (this._isMounted) this.setState({ isLoading: false });
    if (!success) {
      const { ShowConfirmNotif } = Functions;
      this.confirmNotif = ShowConfirmNotif({
        message: "Failed to confirm an order. Please try again.",
        type: "warning",
      });
      return;
    }
    if (_isTrue(allowPrintingOrder)) this.onPrintOrder();
    const {
      customerName,
      deliveryDetails,
      orderDeliveryTypeID,
      phoneNumber,
    } = orderInfo;
    if (phoneNumber) {
      const { SendTextMessage } = Services.BUTI.PostRequests;
      await SendTextMessage({
        body: _createConfirmMsgForCustomer({
          customerName,
          deliveryDetails,
          orderDeliveryTypeID,
          preparationTime,
          shopBasicInfo,
        }),
        to: phoneNumber,
      });
    }
    this.setState({ showExpandedInfo: true }, () =>
      context.onChangeActiveOrders(
        set(context.activeOrders, `${orderID}.status`, nextStatus)
      )
    );
  };

  onCloseActiveOrder = async (params = {}) => {
    const { MoveActiveOrderToPastOrders } = Services.Merchants.PostRequests;
    const { SendTextMessage } = Services.BUTI.PostRequests;
    this.setState({ isLoading: true });
    const { context, orderID, orderInfo } = this.props;
    const { deliveryDetails, orderDeliveryTypeID, phoneNumber } = orderInfo;
    const { success } = await MoveActiveOrderToPastOrders({
      orderID,
      shopID: context.shopID,
    });
    if (this._isMounted) this.setState({ isLoading: false });
    if (!success) {
      const { ShowConfirmNotif } = Functions;
      this.confirmNotif = ShowConfirmNotif({
        message: "Failed to close an order. Please try again.",
        type: "warning",
      });
      return;
    }
    const { sendOrderReadyText = true } = params;
    if (phoneNumber && sendOrderReadyText) {
      await SendTextMessage({
        body: _createOrderReadyMsgForCustomer({
          deliveryDetails,
          orderDeliveryTypeID,
          shopName: _get(this.props.context, "shopBasicInfo.name") || "",
        }),
        to: phoneNumber,
      });
    }
    context.onChangeActiveOrders(del(context.activeOrders, orderID));
  };

  onPrintOrder = () => {
    const { context, orderID, orderInfo } = this.props;
    const { OrdersManagement, ShowConfirmNotif } = Functions;
    const { _printOrder } = OrdersManagement;
    const {
      addedPrinters,
      onConnectToPrintServer = () => {},
      onUpdateAddedPrinters = () => {},
      printerQZConfigs = {},
      shopBasicInfo,
    } = context;
    const { timeZone } = shopBasicInfo;
    Object.keys(addedPrinters).forEach((printerName) => {
      const { printerBrand } = addedPrinters[printerName];
      try {
        _printOrder({
          qzConfigForPrinter: printerQZConfigs[printerName],
          orderID,
          orderInfo,
          printerBrand,
          printerName,
          shopTimeZone: timeZone,
        });
      } catch (error) {
        let message = error;
        if (error === "printServerNotConnected") {
          message = "Print server is not connected";
          onConnectToPrintServer();
        } else if (error === "printerNotConnected") {
          message = `${printerName} printer is not connected`;
          onUpdateAddedPrinters(
            set(addedPrinters, `${printerName}.isConnected`, false)
          );
        }
        this.confirmNotif = ShowConfirmNotif({ message, type: "error" });
      }
    });
  };

  onRefundRequestSuccess = ({ refund_request_id, request_details }) =>
    this.setState({ showRefundModal: false }, () =>
      this.props.onAddRefundRequestToPastOrder({
        orderId: this.props.orderID,
        refund_request_id,
        request_details,
      })
    );

  renderContent = () => {
    const { showExpandedInfo } = this.state;
    const { context, orderID, orderInfo = {}, orderType } = this.props;
    const { status } = orderInfo;
    const { shopID: shop_id } = context;
    return (
      <div className={Style.orderContainer}>
        <div
          className={cx(
            Style.orderPreviewBox,
            Style[status || "active"],
            showExpandedInfo && Style.showExpandedInfo,
            this.state.isLoading && Style.isLoading
          )}
        >
          {this.renderPreviewInfo(orderInfo)}
          <div>
            <Buttons
              isLoading={this.state.isLoading}
              onCloseActiveOrder={this.onCloseActiveOrder}
              onPrintOrder={this.onPrintOrder}
              onShowActionsModal={() =>
                this.setState({ showActionsModal: true })
              }
              onShowConfirmCloseOrder={() =>
                this.setState({ showConfirmCloseOrder: true })
              }
              onShowEstimatePrepTimeModal={() =>
                this.setState({ showEstimatePrepTimeModal: true })
              }
              onShowExpandedInfo={() =>
                this.setState({
                  showExpandedInfo: !this.state.showExpandedInfo,
                })
              }
              onShowRefundModal={() => this.setState({ showRefundModal: true })}
              orderInfo={orderInfo}
              orderType={orderType}
              showExpandedInfo={showExpandedInfo}
            />
          </div>
        </div>
        {showExpandedInfo && (
          <OrderDetails
            orderID={orderID}
            orderInfo={orderInfo}
            orderType={orderType}
            onShowRefundModal={() => this.setState({ showRefundModal: true })}
            shop_id={shop_id}
          />
        )}
      </div>
    );
  };

  renderPreviewInfo = (orderInfo = {}) => (
    <PreviewInfo
      onArrowBtnClick={() =>
        this.setState({ showExpandedInfo: !this.state.showExpandedInfo })
      }
      orderInfo={orderInfo}
      orderType={this.props.orderType}
      showExpandedInfo={this.state.showExpandedInfo}
    />
  );

  render() {
    const {
      showConfirmCloseOrder,
      showEstimatePrepTimeModal,
      showRefundModal,
    } = this.state;
    const { orderInfo } = this.props;
    const { orderDeliveryTypeID, pickUpTime = "", refundRequests } = orderInfo;
    return (
      <React.Fragment>
        {showEstimatePrepTimeModal && (
          <Modals.SelectEstimatePrepTimeModal
            onAcceptOrder={({ preparationTime }) =>
              this.onConfirmActiveOrder({
                nextStatus: "confirmed",
                preparationTime,
              })
            }
            onCloseModal={() =>
              this.setState({ showEstimatePrepTimeModal: false })
            }
            orderInfo={orderInfo}
          />
        )}
        {showConfirmCloseOrder && (
          <Modals.ConfirmCloseOrderModal
            onCloseModal={() => this.setState({ showConfirmCloseOrder: false })}
            onClosePickupOrder={this.onCloseActiveOrder}
            orderDeliveryTypeID={orderDeliveryTypeID}
            pickUpTime={pickUpTime}
          />
        )}
        {showRefundModal && (
          <Modals.ConfirmRefundOrderModal
            onCloseModal={() => this.setState({ showRefundModal: false })}
            onRefundRequestSuccess={this.onRefundRequestSuccess}
            orderID={this.props.orderID}
            orderInfo={orderInfo}
            refundRequests={refundRequests}
          />
        )}
        {this.renderContent()}
      </React.Fragment>
    );
  }
}

ShopOrderPill.propTypes = {
  context: PropTypes.shape({
    activeOrders: PropTypes.object,
    addedPrinters: PropTypes.object,
    onChangeActiveOrders: PropTypes.func.isRequired,
    printerQZConfigs: PropTypes.object,
    shopBasicInfo: PropTypes.object.isRequired,
    shopID: PropTypes.string.isRequired,
  }).isRequired,
  onAddRefundRequestToPastOrder: PropTypes.func.isRequired,
  orderID: PropTypes.string.isRequired,
  orderInfo: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    orderDeliveryTypeID: PropTypes.oneOf(["deliver", "inStore", "pickUp"])
      .isRequired,
    orderItems: PropTypes.object.isRequired,
    paymentIntentID: PropTypes.string,
    phoneNumber: PropTypes.string,
    status: PropTypes.string.isRequired,
    tableNumber: PropTypes.string,
    timeStamp: PropTypes.string.isRequired,
    tipAmount: PropTypes.any,
    totalPriceAfterTax: PropTypes.any.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  orderType: PropTypes.oneOf(["active", "past"]).isRequired,
  showExpandedInfo: PropTypes.bool,
};

ShopOrderPill.defaultProps = {
  onChangeOrderStatus: () => {},
  showExpandedInfo: false,
};

export default withContext(MerchantInterfaceConsumer)(ShopOrderPill);
