// BUTI DINERS, INC. All right Reserved ©

import _get from "lodash.get";

// Utils
import { _formatPhoneNumber } from "utils";

// ------------------------------------------
// Create confirm message for customer after the order is accepted
const _createConfirmMsgForCustomer = ({
  customerName,
  deliveryDetails = {},
  preparationTime,
  shopBasicInfo,
}) => {
  const tracking_url =
    _get(deliveryDetails, "courierConfirmation.tracking_url") || "";
  const tracking_text = !tracking_url
    ? ""
    : ` Click the link below to track your order:\r\n${tracking_url}`;
  const { name: shopName, phoneNumber = "" } = shopBasicInfo;
  const shopNumberText = !phoneNumber
    ? ""
    : ` Call ${_formatPhoneNumber(phoneNumber)} if you have any questions.`;
  const estimateTime =
    preparationTime === "Other"
      ? ""
      : `Your order will be ready in ${preparationTime}.`;
  return `Hi ${customerName}, ${shopName} confirmed your order! ${estimateTime}${shopNumberText}${tracking_text}`;
};

// ------------------------------------------
// Create a text for customer when the order is ready
const _createOrderReadyMsgForCustomer = ({ orderDeliveryTypeID, shopName }) => {
  const message =
    orderDeliveryTypeID === "deliver"
      ? `Your order @${shopName} is on the way!`
      : `Your order @${shopName} is almost ready!`;
  return `${message} Ignore this msg if you received your order.`;
};

export { _createConfirmMsgForCustomer, _createOrderReadyMsgForCustomer };
