// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Style
import Style from "./style.module.scss";

// Field
import { Button, RadioButton, ResponsiveModal } from "fields";

// Lib
import { Constants } from "lib";

// Icons
import { DoordashIcon, PostmatesIcon } from "assets/Icons";

class SelectEstimatePrepTimeModal extends React.Component {
  state = { selectedTimeId: "timeRange2" };

  onAcceptOrder = () => {
    const { AVERAGE_PREPARATION_TIMES: preparationTimes } = Constants;
    const { label } = preparationTimes[this.state.selectedTimeId];
    this.props.onAcceptOrder({ preparationTime: label });
    this.props.onCloseModal();
  };

  renderOrderInfo = () => {
    const { orderInfo = {} } = this.props;
    const {
      customerName,
      deliveryDetails = {},
      orderDeliveryTypeID,
      pickUpTime = "ASAP",
      tableNumber,
    } = orderInfo;
    let extraInfo;
    if (orderDeliveryTypeID === "inStore") {
      extraInfo = (
        <div className={Style.pickupBy}>
          Table: <strong>{tableNumber}</strong>
        </div>
      );
    } else {
      let courier = "";
      if (orderDeliveryTypeID === "pickUp") courier = customerName;
      else {
        const { courier: deliveryCourier } = deliveryDetails;
        courier =
          deliveryCourier === "postmates" ? (
            <PostmatesIcon className={Style.deliveryCourierIcon} />
          ) : (
            <DoordashIcon className={Style.deliveryCourierIcon} />
          );
      }
      extraInfo = (
        <div className={Style.pickupBy}>
          Pickup By: <strong className={Style.courier}>{courier}</strong>
        </div>
      );
    }
    return (
      <div className={Style.orderInfo}>
        <div className={Style.pickupTime}>
          Pickup Time: <strong>{pickUpTime}</strong>
        </div>
        <div>{extraInfo}</div>
      </div>
    );
  };

  renderOptions = () => {
    const { AVERAGE_PREPARATION_TIMES: preparationTimes } = Constants;
    return Object.keys(preparationTimes).map((id) => {
      const { label } = preparationTimes[id];
      return (
        <div className={Style.option} key={id}>
          <RadioButton
            checked={this.state.selectedTimeId === id}
            id={id}
            label={label}
            onClick={(id) => this.setState({ selectedTimeId: id })}
          />
        </div>
      );
    });
  };

  render() {
    return (
      <ResponsiveModal
        contentDescriptionProps={{
          contentDescription: (
            <div>
              Let the guest know how long it takes to prepare this order
            </div>
          ),
          showContentDescription: true,
        }}
        contentLabel="Select estimate preparation time"
        headerProps={{ header: "Preparation time" }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderOrderInfo()}
        <div className={Style.options}>{this.renderOptions()}</div>
        <Button
          className={Style.submitButton}
          color="info"
          name="select the time"
          onClick={this.onAcceptOrder}
        >
          Submit
        </Button>
      </ResponsiveModal>
    );
  }
}

SelectEstimatePrepTimeModal.propTypes = {
  onAcceptOrder: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  orderInfo: PropTypes.shape({
    orderDeliveryTypeID: PropTypes.oneOf(["inStore", "pickUp", "deliver"])
      .isRequired,
    pickUpTime: PropTypes.string,
    tableNumber: PropTypes.string,
  }).isRequired,
};

export default SelectEstimatePrepTimeModal;
