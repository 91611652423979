// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import { set } from "object-path-immutable";

import RegularHoursEditModal from "./RegularHoursEditModal";
import { _createNotifyEmailToSkipliSupport } from "../helperFunctions";

// Context
import { MerchantInterfaceConsumer, withContext } from "context";

// Style
import CommonStyle from "../style.module.scss";
import Style from "./style.module.scss";

// Fields
import { TimePicker } from "fields";

// Icons
import { PencilIcon } from "assets/Icons";

// Lib
import { Constants, Functions, Services } from "lib";

class RegularHours extends React.Component {
  state = {};

  componentDidMount = () => {
    const { shopBasicInfo = {} } = this.props.context;
    const { openHours = {} } = shopBasicInfo;
    this.setState({ openHours });
  };

  onNotifySkipliSupport = async () => {
    const { BUTI } = Services;
    const { SendEmail } = BUTI.PostRequests;

    const { shopBasicInfo, shopID } = this.props.context;
    const { name, timeZone } = shopBasicInfo;
    const { openHours } = this.state;
    const body = await _createNotifyEmailToSkipliSupport({
      openHours,
      shopID,
      timeZone,
    });
    await SendEmail({
      addresses: ["support@skiplinow.com"],
      body,
      subject: `${name} updated open hours`,
    });
  };

  onSaveNewRegularHours = async () => {
    this.setState({ isLoading: true });
    const { Merchants } = Services;
    const { ShowConfirmNotif } = Functions;
    const { SaveChangedOpenHours } = Merchants.PostRequests;
    try {
      const {
        onUpdateShopBasicInfo,
        shopBasicInfo,
        shopID,
      } = this.props.context;
      const { openHours } = this.state;
      const { success } = await SaveChangedOpenHours({
        openHours,
        shopID,
      });
      ShowConfirmNotif({
        message: success
          ? "Updated open hours"
          : "Failed to update open hours. Try again or refresh page.",
        type: success ? "success" : "error",
      });
      if (success) {
        onUpdateShopBasicInfo(set(shopBasicInfo, "openHours", openHours));
        await this.onNotifySkipliSupport();
      }
    } catch {
      ShowConfirmNotif({
        message: "Failed to update open hours. Try again or refresh page.",
        type: "error",
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  renderContent = () => {
    const { DAYS_IN_A_WEEK } = Constants;
    const { openHours = {} } = this.state;
    return Object.keys(DAYS_IN_A_WEEK).map((day) => {
      const { endAt, isClosedAllDay, extraHours = {}, startAt } =
        openHours[day] || {};
      return (
        <div className={Style.day_container} key={day}>
          <div className={Style.weekday_label}>{day}</div>
          {isClosedAllDay ? (
            <div>Closed</div>
          ) : (
            <div>
              {this.renderTime({ endAt, startAt })}
              {Object.keys(extraHours).length > 0 &&
                this.renderExtraHours({ day, extraHours })}
            </div>
          )}
        </div>
      );
    });
  };

  renderExtraHours = ({ day, extraHours = {} }) =>
    Object.keys(extraHours).map((id) => {
      const { endAt = "", startAt = "" } = extraHours[id];
      return (
        <div key={`${day}-${id}`} style={{ marginTop: "6px" }}>
          {this.renderTime({ endAt, startAt })}
        </div>
      );
    });

  renderModuleTitle = () => (
    <div className={CommonStyle.module_title}>
      Regular Hours
      <div className={CommonStyle.module_title_icon}>
        <PencilIcon />
      </div>
    </div>
  );

  renderRegularHoursEditModal = () => {
    const { shopBasicInfo = {} } = this.props.context;
    const { timeZone } = shopBasicInfo;
    return (
      <RegularHoursEditModal
        onChangeOpenHours={(openHours) => this.setState({ openHours })}
        onCloseModal={() =>
          this.setState({ show_regular_hours_edit_modal: false })
        }
        openHours={this.state.openHours}
        onSaveNewRegularHours={this.onSaveNewRegularHours}
        timeZone={timeZone}
      />
    );
  };

  renderTime = ({ endAt = "", startAt = "" }) => {
    const { shopBasicInfo = {} } = this.props.context;
    const { timeZone } = shopBasicInfo;
    return (
      <div className={Style.time}>
        <TimePicker
          class_names={{ container: Style.time_picker }}
          clear_icon={null}
          disabled
          defaultTime={startAt}
          name="Start time"
          timeZone={timeZone}
        />
        <div style={{ margin: "0 6px" }}>-</div>
        <TimePicker
          class_names={{ container: Style.time_picker }}
          clear_icon={null}
          disabled
          defaultTime={endAt}
          name="End time"
          timeZone={timeZone}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.show_regular_hours_edit_modal &&
          this.renderRegularHoursEditModal()}
        <div
          onClick={() => this.setState({ show_regular_hours_edit_modal: true })}
          style={{ cursor: "pointer" }}
        >
          <div style={{ marginTop: "-20px" }}>{this.renderContent()}</div>
        </div>
      </>
    );
  }
}

RegularHours.propTypes = {
  context: PropTypes.shape({
    onUpdateShopBasicInfo: PropTypes.func.isRequired,
    shopBasicInfo: PropTypes.shape({
      openHours: PropTypes.object,
      timeZone: PropTypes.string.isRequired,
    }).isRequired,
    shopID: PropTypes.string.isRequired,
  }).isRequired,
};

export default withContext(MerchantInterfaceConsumer)(RegularHours);
